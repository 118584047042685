<template>
    <v-layout row justify-center>
        <v-dialog v-model="dialog" scrollable persistent max-width="700px">
          <v-card ref="formCard">
          <v-card-title>
            <span class="headline ma-5" v-if="formType === 'CREATED'">Create New Card Definition</span>
            <span class="headline ma-5" v-else-if="formType === 'UPDATE'">Update Card Definition</span>
            <span class="headline ma-5" v-else-if="formType === 'VIEW'">View Card Definition</span>
        </v-card-title>
          <v-card-text>
            <v-form ref="formEntry">
              <v-stepper v-model="e1" class="elevation-0">
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-form ref="formEntryOne">
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 sm8>
                            <v-text-field
                              label="BIN*"
                              clearable
                              hide-details="auto"
                              data-cy="form-bin"
                              type="number"
                              maxlength="6"
                              :disabled="formType === 'VIEW' || formType === 'UPDATE'"
                              v-model="form.bin"
                              required
                              :rules="[rules.requiredField, rules.isNumberAndSixDigits]"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm8>
                            <v-switch
                             v-model="form.isSupported"
                             :disabled="formType === 'VIEW'"
                              :label="`Is Supported Enabled: ${form.isSupported ? 'true' : 'false'}`"
                            ></v-switch>
                          </v-flex>
                          <v-flex xs12 sm8>
                            <v-text-field
                              label="Issuing Institution*"
                              clearable
                              hide-details="auto"
                              v-model="form.issuingInstitution"
                              data-cy="form-issuing-institution"
                              required
                              :disabled="formType === 'VIEW'"
                              :rules="[rules.requiredField]"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm8>
                            <v-autocomplete
                              v-model="form.cardScheme"
                              :items="cardSchemeList"
                              item-text="displayText"
                              item-value="cardSchemeId"
                              label="Card Scheme*"
                              required
                              :disabled="formType === 'VIEW'"
                              :rules="[rules.requiredSelection]"
                              data-cy="form-card-scheme"
                              clearable
                              return-object
                            ></v-autocomplete>
                          </v-flex>
                          <v-flex xs12 sm8>
                            <v-text-field
                              label="Network"
                              clearable
                              hide-details="auto"
                              data-cy="form-network"
                              v-model="selectedNetwork"
                              disabled
                            ></v-text-field>
                          </v-flex>
                          <v-flex  xs12 sm8>
                            <v-autocomplete
                              v-model="form.cardType"
                              :items="cardTypeList"
                              label="Card Type*"
                              required :rules="[rules.requiredSelection]"
                              data-cy="form-card-type"
                              clearable
                              :disabled="formType === 'VIEW'"
                              return-object
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-form>
          </v-card-text>
          <v-card-actions class="ma-5">
            <small v-if="formType != 'VIEW'">*indicates required field</small>
            <v-spacer></v-spacer>
            <v-btn color="primary" style="min-width: 100px;"  v-on:click="close" data-cy="close-button">Close</v-btn>
            <v-btn color="primary" style="min-width: 100px;"  v-if="formType != 'VIEW'" v-on:click="save" data-cy="save-button">Save</v-btn>
        </v-card-actions>
        </v-card>
        </v-dialog>
    </v-layout>
  </template>
  
  <script>
  import {
mapGetters
} from 'vuex';
  
  export default {
    props: {
      isShow: Boolean,
      isNotPaymentAgent: Boolean,
      formType: String,
      loadingDialog: Boolean,
      selectedItem: Object,
      countries: Array,
      cardSchemes: Array,
      merchantCategoryCodes: Array,
      paymentAgents: Array
    },
    computed: {
      selectedNetwork() {
        // Check if this.form.cardScheme.acquirer.acquirerName exists
        if (this.form.cardScheme && this.form.cardScheme.acquirer && this.form.cardScheme.acquirer.acquirerName !== undefined) {
          return this.form.cardScheme.acquirer.acquirerName;
        } else if (this.form.cardScheme && this.form.cardScheme.acquirerName) {
          // Fallback to this.form.cardScheme.acquirer.acquirerName if the deeper property is undefined
          return this.form.cardScheme.acquirerName;
        }
        // Return an empty string if none of the conditions are met
        return '';
      },
      // set map getters
      isMerchantVerified() {
        return !!this.form?.merchantVerificationValue?.trim();
      },
      ...mapGetters([
              'attributes',
              'merchant',
              'authorities'
          ]),
  
        dynamicStepMDR() {
          return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 2 : -1
        },
        dynamicStepOD() {
          return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 3 : 2
        },
        dynamicSteDBD() {
          return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 4 : 3
        },
        dynamicStepCDF() {
          return this.hasPermission('ROLE_DBP_VIEW_MERCHANT_DETAILS') ? 5 : 4 
        },
      
      populatedForm() {
        return {
          formType: this.formType,
          bin: this.form.bin,
          issuingInstitution: this.form.issuingInstitution,
          isSupported: this.form.isSupported,
          network: this.form.cardScheme ? this.form.cardScheme.acquirer.acquirerName : '',
          cardType: this.form.cardType,
          cardScheme: this.form.cardScheme.cardSchemeId,
        };
      },
    },
    watch: {
      isShow: {
        handler(value) {
          this.dialog = value;
        },
      },
      loadingDialog: {
        handler(value) {
          this.loading = value;
        },
      },
      dialog: {
        handler(value) {
          if (value === true) {
            this.initializeForm();
          }
        },
      },
      cardSchemes: {
            handler(value) {
                this.cardSchemeList = value
            }
        },
    },
    data() {
      return {
        loading: false,
        dialog: this.isShow ?? false,
        loading: this.loadingDialog ?? false,
        countryList: this.countries ?? [],
        cardSchemeList: this.cardSchemes ?? [],
        paymentAgentList: this.paymentAgents ?? [],
        cardTypeList: ['Credit', 'Debit', 'Loyalty'],
        form: {
          country: {},
          merchantCategoryCode: {},
          paymentAgent: {},
          cardSchemes: {}
        },
        rules: {
          requiredField: (v) => !!v || "Required field.",
          isNumberAndSixDigits: value => {
                const pattern = /^\d{6}$/;
                return pattern.test(value) || 'Must be a number and exact 6 digits';
            },
          requiredSelection: (v) =>
            (!!v) || "Required at least one selection",
          properEmail: (v) =>
            !v ||
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid. Ex. juandelacruz@apollo.com.ph",
          maxLength: (v) => (v && v.length <= 4) || "Max 4 characters",
          positiveIntegers: (v) =>
            (!isNaN(v) && /^[0-9]*$/.test(v)) || "Must be positive integers only",
          merchantCodeFormat: (v) => !v || /^[a-zA-Z0-9-_\.]+$/.test(v) || "Must be alphanumeric, only accepts dash(-), underscore(_) and period(.).",
          mobileNumber: v => !v || /^(09)\d{9}$/.test(v) || 'Mobile number must be valid. Ex. starts with (09) followed by xxxxxxxxx, where x = numeric character only', 
          ///^(09|\+639)\d{9}$/ <- if needed full philippine mobile number 
        },
        amountRule: [
          (v) => !!v || 'Amount is required',
          (v) => /^\d{1,2}(\.\d{1,2})?$/.test(v) || 'Invalid amount format',
          (v) => v >= 0 && v <= 100 || 'Amount must be between 0.00 and 100.00',
        ],
        e1: 1,
        isFormComplete: false,
      };
    },
    mounted() {
      this.initializeForm();
    },
    methods: {
      getValidationRules() {
        return this.form.mvv ? [this.hexadecimalRule] : [];
      },
      hexadecimalRule(value) {
        const hexPattern = /^[0-9A-Fa-f]{10}$/;
        return hexPattern.test(value) || 'Input must be a valid hexadecimal value of length 10';
      },
      transformToUpperCase() {
        // Directly modify the model value to uppercase
        this.form.merchantVerificationValue = this.form.merchantVerificationValue.toUpperCase();
      },
      //get roles attributes
      hasPermission(authority) {
            if(this.authorities.some(x => x.name === authority)){
              return true
            }
            return false
      },
      close() {
        this.dialog = false;
        this.$refs.formEntry.reset();
        this.$refs.formCard.$el.scrollIntoView({
          behavior: "smooth",
        });
        this.$emit("close-dialog", this.formType);
      },
      save() {
        if (this.$refs.formEntry.validate()) {
            this.$emit('save-card-definition', this.populatedForm)
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
        }
      },
      resetForm() {
        this.$refs.formEntry.reset();
      },
      initializeForm() {
        this.e1 = 1;
        if (this.formType === "CREATED") {
          this.form = {
            bin: undefined,
            isSupported: true,
            cardScheme: undefined,
            issuingInstitution: undefined,
            network: undefined,
            cardType: undefined
          };
        } else if ((this.formType === 'UPDATE' || this.formType === 'VIEW') && this.selectedItem) {
          const cardSchemeObject = JSON.parse(this.selectedItem.scheme);
          console.log("TEST" + cardSchemeObject)
          this.form = {
            bin: this.selectedItem.bin,
            isSupported: this.selectedItem.isSupported,
            cardScheme: cardSchemeObject,
            issuingInstitution: this.selectedItem.issuingInstitution,
            network: this.selectedItem.network,
            cardType: this.selectedItem.type
          };
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .stepper-1-btns {
    margin-top: 30px !important;
  }
  
  .stepper-2-btns {
    margin-top: 74px !important;
  }
  
  </style>
  